import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';

export default function NotFoundPage() {
    return (
        <Layout>
            <Seo title="404" description="Page Not Found" />
            <div className="app-hero">
                <div className="container">
                    <div className="hero">
                        <div className="hero__txt">
                            <h1>Page Not Found</h1>
                            <p>
                                Sorry the page you're looking for cannot be
                                found. For more information contact{' '}
                                <a
                                    href="mailto:info@digiclinic.com.au"
                                    className="errorlink"
                                >
                                    info@digiclinic.com.au
                                </a>
                            </p>
                        </div>
                        <div className="hero__img">
                            <Image
                                src="title-img.png"
                                alt="Management Dashboard"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
